import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout/Layout';

class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges;
    const postLinks = posts.map((post) => (
      <li
        key={post.node.fields.slug}
        className='py-4 text-white hover:text-yellow-700'
      >
        <Link to={post.node.fields.slug}>{post.node.frontmatter.title}</Link>
      </li>
    ));
    const tag = this.props.pageContext.tag;
    const title = this.props.data.site.siteMetadata.title;
    const totalCount = this.props.data.allMarkdownRemark.totalCount;
    const tagHeader = `${totalCount} post${
      totalCount === 1 ? '' : 's'
    } tagged with “${tag}”`;

    return (
      <Layout>
        <section className='flex-grow'>
          <Helmet title={`${tag} | ${title}`} />
          <div className='lg:max-w-7xl mx-auto px-4 mt-4'>
            <div className='py-6 text-center'>
              <h1 className='text-lg text-yellow-700 font-semibold tracking-wide uppercase'>
                tags
              </h1>
              <h2 className='mt-2 text-6xl font-extrabold tracking-tight text-white'>
                {tagHeader}
              </h2>
              <h3 className='mt-4 mb-8 max-w-2xl mx-auto text-2xl font-normal text-gray-400'>
                See tagged blog posts below
              </h3>
              <div className='text-justify'>
                <h1 className='mt-2 text-4xl font-extrabold tracking-tight text-white'>
                  blog posts
                </h1>
                <ul className='text-white mb-8'>{postLinks}</ul>
                <Link
                  to='/tags/'
                  className='ml-6 items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-normal bg-white hover:bg-yellow-200 text-yellow-700 border-yellow-700'
                >
                  Browse all tags
                </Link>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default TagRoute;

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
